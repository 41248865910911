.copy-text-container {
    position: relative;
  }
  
  .copy-text-container.hovered .copy-icon {
    font-weight: bold;
    cursor: pointer;
  }
  
  .text-to-copy.hovered {
    font-weight: bold;
    cursor: pointer;
  }
  